.footlink{
    text-align: center;
}

.footlink a{
    margin: 0 1% 0 1%;
}

address{
    font-size: 90%;
}

nav a {
    color: floralwhite;
}

nav a:hover {
    color: floralwhite;
}

.breadcrumb {
    background-color: black;
}

.media-body a {
    text-decoration: none;
    color: floralwhite;
}

.container-carousel {
    position: relative;
    text-align: center;
  }

.bottom-center {
    position: absolute;
    bottom: 3%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.grow-load {
    opacity: 1;
    animation: fade 2.5s linear forwards infinite;
}


@keyframes fade {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }

}

.spin-load {
    border: 4px solid #121212; /* Light grey */
    border-top: 4px solid white; /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2.5s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}